import React, { useEffect } from 'react';
import { useState } from 'react';
import { TextField, InputAdornment, FormHelperText } from '@mui/material';
import CustomButton from '../../components/CustomButton';
import { useAppDispatch, useAppSelector } from '../../store/reduxHooks';
import { login } from './authActions';
import { Login } from './auth.types';
import { clearError } from './authSlice';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import {
  Wrapper,
  LoginImgWrapper,
  LoginText,
  ButtonRowWrapper,
  FieldWrapper,
  Title,
  LogoImg,
  ForgotPasswordLink,
  CustomIcon,
} from './authStyles';
import StickyErrorMessageBox from '../../components/StickyErrorMessageBox';
const initialLogin = {
  email: '',
  password: '',
  source: 'toppers',
  latitude: 0,
  longitude: 0,
};
const LoginContainer = () => {
  const dispatch = useAppDispatch();
  const responseError = useAppSelector(
    (state) => state.authReducer.responseError
  );
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [loginObj, setLoginObj] = useState<Login>(initialLogin);
  const [error, setError] = useState<string>('');

  const isEmailValid = (email: string) => {
    const isValidEmail = String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
      ? true
      : false;
    return isValidEmail;
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let newLogin = { ...loginObj };
    if (name === 'email') {
      const isValid = isEmailValid(value);
      if (isValid) {
        setError('');
      } else if (!newLogin.email) {
        setError('Required');
      } else {
        setError('Email with a valid format is required.');
      }
    }
    newLogin = { ...newLogin, [name]: value };
    setLoginObj(newLogin);
  };
  const handleLogin = () => {
    const updatedLogin = { ...loginObj };
    dispatch(login(updatedLogin));
  };
  useEffect(() => {
    if (!loginObj.email) {
      setError('Required');
    }
    return () => {
      dispatch(clearError());
    };
  }, [dispatch, clearError]);
  return (
    <Wrapper>
      <LoginImgWrapper>
        <LogoImg src='https://s3.ca-central-1.amazonaws.com/assets.dev.smoothpay.com/toppers/Toppers+Pizza+-+Cravers+Rewards+Logo+-+White.png' />
      </LoginImgWrapper>
      {responseError && <StickyErrorMessageBox errors={responseError} />}
      <LoginText>
        <Title>Welcome to Topper's Pizza</Title>
        <FieldWrapper>
          <TextField
            type='text'
            fullWidth
            size='small'
            name='email'
            label='Email *'
            placeholder='Email'
            value={loginObj.email}
            onChange={handleChange}
            error={!loginObj.email ? true : false}
            sx={{
              backgroundColor: '#fff',
            }}
          />
          {error && (
            <FormHelperText style={{ color: 'red', marginLeft: '5px' }}>
              {error}
            </FormHelperText>
          )}
        </FieldWrapper>
        <FieldWrapper height='60px'>
          <TextField
            type={showPassword ? 'text' : 'password'}
            fullWidth
            size='small'
            name='password'
            label='Password *'
            placeholder='Password'
            value={loginObj.password}
            onChange={handleChange}
            sx={{
              backgroundColor: '#fff',
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <CustomIcon
                    icon={showPassword ? faEye : faEyeSlash}
                    onClick={() => setShowPassword(!showPassword)}
                  />
                </InputAdornment>
              ),
            }}
          />
        </FieldWrapper>
        <ForgotPasswordLink to='/forgot-password' replace={true}>
          Forgot Password?
        </ForgotPasswordLink>
        <ButtonRowWrapper>
          <CustomButton
            onClick={handleLogin}
            disabled={
              !loginObj.email || !loginObj.password || error ? true : false
            }
            padding='10px 50px'
          >
            Login
          </CustomButton>
        </ButtonRowWrapper>
      </LoginText>
    </Wrapper>
  );
};

export default LoginContainer;
