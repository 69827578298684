import React, { useEffect } from 'react';
import './App.css';
import Header from './modules/header/Header';
import { useAppDispatch } from './store/reduxHooks';
import { getConfig, getSession } from './modules/auth/authActions';
import Content from './modules/content';

function App() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getConfig(null));
    dispatch(getSession(null));
  }, [dispatch]);
  return (
    <>
      <Header />
      <section className='login-wrapper'>
        <Content />
      </section>
    </>
  );
}

export default App;
