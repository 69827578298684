import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
const root_url = process.env.REACT_APP_ROOT_URL;
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: root_url,
      },
      serializableCheck: false,
    }),
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
