import * as React from 'react';
import { RegistrationProps } from './auth.types';
import { Wrapper, LoginImgWrapper, LoginText, LogoImg } from './authStyles';
import RegistrationForm from './RegistrationForm';
import { useAppSelector } from '../../store/reduxHooks';
import StickyErrorMessageBox from '../../components/StickyErrorMessageBox';
const RegistrationContainer: React.FC<RegistrationProps> = ({
  enteredPhoneNumber,
  setEnteredPhoneNumber,
}) => {
  const responseError = useAppSelector(
    (state) => state.authReducer.responseError
  );
  return (
    <Wrapper>
      <LoginImgWrapper>
        <LogoImg src='https://s3.ca-central-1.amazonaws.com/assets.dev.smoothpay.com/toppers/Toppers+Pizza+-+Cravers+Rewards+Logo+-+White.png' />
      </LoginImgWrapper>
      {responseError && <StickyErrorMessageBox errors={responseError} />}
      <LoginText>
        <RegistrationForm
          enteredPhoneNumber={enteredPhoneNumber}
          setEnteredPhoneNumber={setEnteredPhoneNumber}
        />
      </LoginText>
    </Wrapper>
  );
};

export default RegistrationContainer;
