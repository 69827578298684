import * as React from 'react';
import { ForgotPaswordMethodSelectionProps } from './auth.types';
import { faEnvelope, faCommentAlt } from '@fortawesome/free-regular-svg-icons';
import {
  ForgotPasswordContentWrapper,
  Title,
  Message,
  MethodWrapper,
  Icon,
  MethodTitle,
} from './authStyles';

const ForgotPasswordMethodSelection: React.FC<
  ForgotPaswordMethodSelectionProps
> = ({ setStep }) => {
  return (
    <ForgotPasswordContentWrapper>
      <Title align='left'>Forgot Password</Title>
      <Message>
        We will send you a verification code to reset your password. How do you
        want it sent?
      </Message>
      <MethodWrapper onClick={() => setStep({ id: 1, method: 'email' })}>
        <MethodTitle>Via Email</MethodTitle>
        <Icon icon={faEnvelope}></Icon>
      </MethodWrapper>
      <MethodWrapper
        margin='10px 0 0'
        onClick={() => setStep({ id: 1, method: 'sms' })}
      >
        <MethodTitle>Via SMS</MethodTitle>
        <Icon icon={faCommentAlt}></Icon>
      </MethodWrapper>
    </ForgotPasswordContentWrapper>
  );
};

export default ForgotPasswordMethodSelection;
