import React, { useState } from 'react';
import { Formik } from 'formik';
import { TextField, FormHelperText, InputAdornment } from '@mui/material';
import { ButtonWrapper } from './authStyles';
import CustomButton from '../../components/CustomButton';
import { useAppDispatch, useAppSelector } from '../../store/reduxHooks';
import { object, string } from 'yup';
import { resetPassword } from './authActions';
import PasswordField from '../../components/PasswordField';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import {
  ForgotPasswordWrapper,
  ForgotPasswordContentWrapper,
  Title,
  BackToSelection,
  CustomIcon,
} from './authStyles';
import StickyErrorMessageBox from '../../components/StickyErrorMessageBox';
const PasswordReset: React.FC = () => {
  const dispatch = useAppDispatch();
  const minPasswordLength = useAppSelector(
    (state) => state.authReducer.minPasswordLength
  );
  const responseError = useAppSelector(
    (state) => state.authReducer.responseError
  );
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [minLengthCheck, setMinLengthCheck] = useState<boolean>(false);
  const [oneNumberCheck, setOneNumberCheck] = useState<boolean>(false);
  const [oneSymbolCheck, setOneSymbolCheck] = useState<boolean>(false);
  const [oneUpperCheck, setOneUpperCheck] = useState<boolean>(false);
  const [oneLowerCheck, setOneLowerCheck] = useState<boolean>(false);
  const initialValue = { password: '', re_password: '' };
  const validationSchema = object().shape({
    password: string()
      .required('Password is required')
      .test('isValidPassword', 'Not Valid Password', (value) => {
        let isValid = [false, false, false, false, false];
        const checkOneNumber = new RegExp('[0-9]').test(value);
        const checkOneLower = new RegExp('(?=.*[a-z])').test(value);
        const checkOneUpper = new RegExp('(?=.*[A-Z])').test(value);
        let specialChars = /[`!@#$%^&*()_\-+=\[\]{};':"\\|,.<>\/?~ ]/;
        const checkOneSymbol = specialChars.test(value);
        let len = value.length;
        if (len >= minPasswordLength) {
          setMinLengthCheck(true);
          isValid[0] = true;
        } else {
          setMinLengthCheck(false);
          isValid[0] = false;
        }
        if (checkOneNumber) {
          setOneNumberCheck(true);
          isValid[1] = true;
        } else {
          setOneNumberCheck(false);
          isValid[1] = false;
        }
        if (checkOneLower) {
          setOneLowerCheck(true);
          isValid[2] = true;
        } else {
          setOneLowerCheck(false);
          isValid[2] = false;
        }
        if (checkOneUpper) {
          setOneUpperCheck(true);
          isValid[3] = true;
        } else {
          setOneUpperCheck(false);
          isValid[3] = false;
        }
        if (checkOneSymbol) {
          setOneSymbolCheck(true);
          isValid[4] = true;
        } else {
          setOneSymbolCheck(false);
          isValid[4] = false;
        }
        return isValid.every((con) => con === true);
      }),
    re_password: string()
      .required('Password is required')
      .test('equal', 'Password does not match!', function (value) {
        return this.parent.password === value;
      }),
  });
  return (
    <ForgotPasswordWrapper>
      {responseError && (
        <StickyErrorMessageBox errors={responseError} borderRadius='15px' />
      )}
      <ForgotPasswordContentWrapper>
        <BackToSelection></BackToSelection>
        <Title align='left'>Create New Password</Title>
        <Formik
          initialValues={initialValue}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            dispatch(resetPassword({ password: values.password }));
          }}
        >
          {({
            values,
            errors,
            touched,
            dirty,
            handleChange,
            handleBlur,
            handleSubmit,
            // setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <PasswordField
                oneLowerCheck={oneLowerCheck}
                oneUpperCheck={oneUpperCheck}
                oneSymbolCheck={oneSymbolCheck}
                minLengthCheck={minLengthCheck}
                minPasswordLength={minPasswordLength}
                oneNumberCheck={oneNumberCheck}
                handleChange={handleChange}
                values={values}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
              />
              <TextField
                id='RegistrationForm-Email-Field'
                fullWidth
                label='Enter Code'
                name='re_password'
                type={showPassword ? 'text' : 'password'}
                size='small'
                autoComplete='username'
                value={values.re_password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.re_password && errors.re_password ? true : false}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <CustomIcon
                        icon={showPassword ? faEye : faEyeSlash}
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  backgroundColor: '#fff',
                }}
                autoFocus
              />
              {touched.re_password && errors.re_password && (
                <FormHelperText
                  style={{ color: 'red', marginLeft: '5px' }}
                  error={
                    touched.re_password && errors.re_password ? true : false
                  }
                >
                  {errors.re_password}
                </FormHelperText>
              )}

              <ButtonWrapper margin='10px 0 0 0'>
                <CustomButton
                  type='submit'
                  disabled={Object.keys(errors).length > 0 || !dirty}
                  padding='10px 50px'
                >
                  Continue
                </CustomButton>
              </ButtonWrapper>
            </form>
          )}
        </Formik>
      </ForgotPasswordContentWrapper>
    </ForgotPasswordWrapper>
  );
};

export default PasswordReset;
