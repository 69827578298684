import { Fragment, useState } from 'react';
import { Wrapper, ContentContainer } from './auth/authStyles';
import { Routes, Route } from 'react-router-dom';
import RegistrationContainer from './auth/RegistrationContainer';
import LoginContainer from './auth/LoginContainer';
import VerificationContainer from './auth/VerificationContainer';
import ForgotPasswordContainer from './auth/ForgotPasswordContainer';
const Content: React.FC = () => {
  const [enteredPhoneNumber, setEnteredPhoneNumber] = useState<string>('');
  const [enteredEmail, setEnteredEmail] = useState<string>('');
  return (
    <Wrapper>
      <ContentContainer>
        <Routes>
          <Route
            path='/'
            element={
              <Fragment>
                <RegistrationContainer
                  enteredPhoneNumber={enteredPhoneNumber}
                  setEnteredPhoneNumber={setEnteredPhoneNumber}
                />
              </Fragment>
            }
          />
          <Route
            path='forgot-password'
            element={
              <Fragment>
                <ForgotPasswordContainer
                  enteredPhoneNumber={enteredPhoneNumber}
                  setEnteredPhoneNumber={setEnteredPhoneNumber}
                  enteredEmail={enteredEmail}
                  setEnteredEmail={setEnteredEmail}
                />
              </Fragment>
            }
          />
          <Route
            path='login'
            element={
              <Fragment>
                <LoginContainer />
              </Fragment>
            }
          />
          <Route
            path='verify'
            element={
              <Fragment>
                <VerificationContainer
                  enteredPhoneNumber={enteredPhoneNumber}
                  setEnteredPhoneNumber={setEnteredPhoneNumber}
                />
              </Fragment>
            }
          />
        </Routes>
      </ContentContainer>
    </Wrapper>
  );
};

export default Content;
