import * as React from 'react';
import { Formik } from 'formik';
import { TextField, FormHelperText } from '@mui/material';
import { ForgotPaswordMethodProps } from './auth.types';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import PhoneInput from 'react-phone-input-2';
import { ButtonWrapper } from './authStyles';
import CustomButton from '../../components/CustomButton';
import { object, string } from 'yup';
import { forgotPassword } from './authActions';
import { useAppDispatch } from '../../store/reduxHooks';
import {
  ForgotPasswordContentWrapper,
  Title,
  Message,
  Icon,
  BackText,
  BackToSelection,
} from './authStyles';
import { clearError } from './authSlice';
const validationSchemaEmail = object().shape({
  email: string()
    .matches(
      new RegExp(
        '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@' +
          '[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$'
      ),
      'Please enter a valid email format'
    )
    .required('Email is required'),
});
const validationSchemaSMS = object().shape({
  phone_number: string()
    .required('Required')
    .min(10, 'Phone number must be at least 10 characters'),
});
const ForgotPasswordMethod: React.FC<ForgotPaswordMethodProps> = ({
  enteredPhoneNumber,
  setEnteredPhoneNumber,
  enteredEmail,
  setEnteredEmail,
  step,
  setStep,
}) => {
  const dispatch = useAppDispatch();
  const initialValue =
    step?.method === 'email'
      ? { email: '', source: 'toppers' }
      : { phone_number: '', source: 'toppers' };
  const validationSchema =
    step?.method === 'email' ? validationSchemaEmail : validationSchemaSMS;
  return (
    <ForgotPasswordContentWrapper>
      <BackToSelection
        onClick={() => {
          setStep({ id: 0, method: 'email' });
          dispatch(clearError());
        }}
      >
        <Icon icon={faChevronLeft} fontSize='15px'></Icon>
        <BackText>Back</BackText>
      </BackToSelection>
      <Title align='left'>Forgot Password</Title>
      <Message>
        {step?.method === 'email'
          ? 'Enter your email below to update your password. If there is an account linked to this email, you will receive a verification code.'
          : 'Enter your phone number below to update your password. If there is an account linked to this email, you will receive a verification code.'}
      </Message>
      <Formik
        initialValues={initialValue}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          let payload;
          if (step?.method === 'email') {
            payload = {
              email: values.email,
              source: 'toppers',
            };
          } else {
            payload = {
              phone_number: values.phone_number,
              source: 'toppers',
            };
          }
          dispatch(forgotPassword({ payload, setStep, step }));
        }}
      >
        {({
          values,
          errors,
          touched,
          dirty,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <>
              {step?.method === 'email' ? (
                <>
                  <TextField
                    id='RegistrationForm-Email-Field'
                    fullWidth
                    label='Email'
                    name='email'
                    type='email'
                    size='small'
                    autoComplete='username'
                    value={values.email}
                    onChange={(e) => {
                      setFieldValue('email', e.target.value);
                      setEnteredEmail(e.target.value);
                      setEnteredPhoneNumber('');
                    }}
                    onBlur={handleBlur}
                    error={touched.email && errors.email ? true : false}
                    sx={{
                      backgroundColor: '#fff',
                    }}
                    autoFocus
                  />
                  {touched.email && errors.email && (
                    <FormHelperText
                      style={{ color: 'red', marginLeft: '5px' }}
                      error={touched.email && errors.email ? true : false}
                    >
                      {errors.email}
                    </FormHelperText>
                  )}
                </>
              ) : (
                <>
                  <PhoneInput
                    specialLabel='Phone Number'
                    placeholder='(123) 456-7890'
                    country={'ca'}
                    onlyCountries={['ca', 'us']}
                    masks={{ ca: '(...) ...-....', us: '(...) ...-....' }}
                    value={values.phone_number}
                    onChange={(phone) => {
                      setFieldValue('phone_number', phone);
                      setEnteredPhoneNumber(phone);
                      setEnteredEmail('');
                    }}
                    onBlur={handleBlur}
                    isValid={
                      touched.phone_number && errors.phone_number ? false : true
                    }
                    inputStyle={{ width: '100%', height: '40px' }}
                    inputProps={{
                      name: 'phone_number',
                    }}
                    disableCountryCode={true}
                  />
                  {touched.phone_number && errors.phone_number && (
                    <FormHelperText
                      style={{ color: 'red', marginLeft: '5px' }}
                      error={
                        touched.phone_number && errors.phone_number
                          ? true
                          : false
                      }
                    >
                      {errors.phone_number}
                    </FormHelperText>
                  )}
                </>
              )}
              <ButtonWrapper margin='10px 0 0 0'>
                <CustomButton
                  type='submit'
                  disabled={Object.keys(errors).length > 0 || !dirty}
                  padding='10px 50px'
                >
                  Continue
                </CustomButton>
              </ButtonWrapper>
            </>
          </form>
        )}
      </Formik>
    </ForgotPasswordContentWrapper>
  );
};

export default ForgotPasswordMethod;
