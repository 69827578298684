import { useState, useRef } from 'react';
import { Dialog } from '@mui/material';
import AuthCode, { AuthCodeRef } from 'react-auth-code-input';
import CustomButton from '../../components/CustomButton';
import { useAppDispatch, useAppSelector } from '../../store/reduxHooks';
import { confirmSMS, resendRegistrationCode } from './authActions';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PhoneInput from 'react-phone-input-2';
import { useNavigate } from 'react-router-dom';
import { RegistrationProps } from './auth.types';
import StickyErrorMessageBox from '../../components/StickyErrorMessageBox';
import { clearError } from './authSlice';
import {
  VerificationAndChangeNumberWrapper,
  ModalWrapper,
  TitleWrapper,
  Cancel,
  MessageWrapper,
  ActionWrapper,
  Action,
  MessageContainer,
  Title,
  CodeWrapper,
} from './authStyles';

const VerificationContainer: React.FC<RegistrationProps> = ({
  enteredPhoneNumber,
  setEnteredPhoneNumber,
}) => {
  const AuthInputRef = useRef<AuthCodeRef>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const responseError = useAppSelector(
    (state) => state.authReducer.responseError
  );
  const [verifyButtonText, setVerifyButtonText] = useState<string>('');
  const [verfiyModalMessage, setVerfiyModalMessage] = useState<string>('');
  const [resendCodeModalIsVisible, setResendCodeModalIsVisible] =
    useState<boolean>(false);

  const handleOnChange = (code: string) => {
    if (code.length >= 4) {
      const newConfirmCodeObject = { sms_code: code };
      dispatch(confirmSMS({ payload: newConfirmCodeObject, navigate }));
      AuthInputRef.current?.clear();
    }
  };
  const resendCode = () => {
    const resendObject = {
      phone_number: enteredPhoneNumber,
      platform_id: 3,
    };
    dispatch(
      resendRegistrationCode({
        payload: resendObject,
        setResendCodeModalIsVisible,
      })
    );
    AuthInputRef.current?.clear();
    // setErrors({});
  };
  const showVerifyModal = (type: string) => {
    dispatch(clearError());
    let title;
    let verifyButtonText;
    if (type === 'resend') {
      title = 'Verify Phone Number';
      verifyButtonText = 'Resend Code';
    } else {
      title = 'Update Phone Number';
      verifyButtonText = 'Resend Code';
    }
    setVerifyButtonText(verifyButtonText);
    setVerfiyModalMessage(title);
    setResendCodeModalIsVisible(true);
  };
  return (
    <form>
      <VerificationAndChangeNumberWrapper>
        {responseError && !resendCodeModalIsVisible && (
          <StickyErrorMessageBox errors={responseError} borderRadius='15px' />
        )}
        <Title>Verification</Title>
        <MessageContainer>
          <MessageWrapper>
            We sent a verification code to
            <span
              style={{
                display: 'inline-block',
                marginRight: '5px',
                marginLeft: '5px',
              }}
            >
              {enteredPhoneNumber}
            </span>
            in order to ensure a secure sign-up. Please enter the code below.
          </MessageWrapper>
        </MessageContainer>
        <CodeWrapper>
          <AuthCode
            containerClassName='verification-input-container'
            inputClassName='verification-input'
            allowedCharacters='numeric'
            onChange={handleOnChange}
            length={4}
            ref={AuthInputRef}
          />
        </CodeWrapper>
        <ActionWrapper>
          <Action onClick={() => showVerifyModal('resend')}>Resend Code</Action>
          <Action onClick={() => showVerifyModal('change')}>
            Use a Different Phone Number
          </Action>
        </ActionWrapper>
      </VerificationAndChangeNumberWrapper>
      {resendCodeModalIsVisible && (
        <Dialog
          open={resendCodeModalIsVisible}
          onBackdropClick={() => setResendCodeModalIsVisible(false)}
          fullWidth={window.innerWidth < 786}
          fullScreen={window.innerWidth < 786}
          scroll='body'
          style={{ borderRadius: '15px' }}
        >
          {responseError && (
            <StickyErrorMessageBox
              errors={responseError}
              borderRadius='4px'
              marginTop='0px'
            />
          )}
          <ModalWrapper>
            <TitleWrapper>
              <Title id='Registration-Title' align='left'>
                {verfiyModalMessage}
              </Title>
              <Cancel
                id='Registration-CancelButton'
                onClick={() => {
                  setResendCodeModalIsVisible(false);
                  //   clearAuthErrorMessage();
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Cancel>
            </TitleWrapper>
            <PhoneInput
              specialLabel='Phone Number'
              placeholder='(123) 456-7890'
              country={'ca'}
              onlyCountries={['ca', 'us']}
              masks={{ ca: '(...) ...-....', us: '(...) ...-....' }}
              value={enteredPhoneNumber}
              onChange={(phone) => setEnteredPhoneNumber(phone)}
              inputStyle={{ width: '100%', height: '40px' }}
              inputProps={{
                name: 'phone_number',
              }}
              disableCountryCode={true}
            />
            <div style={{ margin: '0 auto' }}>
              <CustomButton disabled={false} type='button' onClick={resendCode}>
                {verifyButtonText}
              </CustomButton>
            </div>
          </ModalWrapper>
        </Dialog>
      )}
    </form>
  );
};

export default VerificationContainer;
