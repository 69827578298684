import { Wrapper, Logo, HeaderWrapper } from './headerStyles';
function Header() {
  return (
    <Wrapper>
      <HeaderWrapper>
        <Logo
          src='https://storage.googleapis.com/bro-cdn1/zgrid/themes/781/images/logo.png'
          alt="Topper's Pizza Newmarket"
        />
      </HeaderWrapper>
    </Wrapper>
  );
}

export default Header;
